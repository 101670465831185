// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          attribute: js.attribute,
          message: js.message
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              attribute: field.required("attribute", Json_Decode$JsonCombinators.string),
              message: field.required("message", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function fromJs$1(js) {
  return {
          activeModelErrors: Belt_Array.map(js.activeModelErrors, fromJs)
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              activeModelErrors: field.required("activeModelErrors", Json_Decode$JsonCombinators.array(decoder))
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Errors = {
  fromJs: fromJs$1,
  decoder: decoder$1,
  fromJson: fromJson$1
};

var container = Cx.cx([
      "flex",
      "flex-col",
      "gap-4"
    ]);

var list = Cx.cx([
      "list-disc",
      "list-inside"
    ]);

var Css = {
  container: container,
  list: list
};

function ActiveModelError$List(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                "Please fix the following errors:",
                JsxRuntime.jsx("ul", {
                      children: Belt_Array.mapWithIndex(props.errors, (function (index, message) {
                              return JsxRuntime.jsx("li", {
                                          children: message.message
                                        }, String(index));
                            })),
                      className: list
                    })
              ],
              className: container
            });
}

var List = {
  Css: Css,
  make: ActiveModelError$List
};

export {
  fromJs ,
  decoder ,
  fromJson ,
  Errors ,
  List ,
}
/* decoder Not a pure module */
