// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var DocumentId = ID.Int({});

function fromJs($$document) {
  return {
          id: $$document.id,
          filename: $$document.filename,
          byteSize: $$document.byteSize,
          createdAt: $$Date.Utc.fromString($$document.createdAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", DocumentId.decoder),
              filename: field.required("filename", Json_Decode$JsonCombinators.string),
              byteSize: field.required("byteSize", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Utc.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toKiloBytes(bytes) {
  return bytes / 1000.0;
}

function toMegaBytes(bytes) {
  return bytes / 1000000.0;
}

function sizeText(bytes) {
  if (bytes < 1000000) {
    return (bytes / 1000.0).toFixed(1) + " KB";
  } else {
    return (bytes / 1000000.0).toFixed(1) + " MB";
  }
}

var Resource = {
  fromJs: fromJs,
  decoder: decoder,
  fromJson: fromJson,
  toKiloBytes: toKiloBytes,
  toMegaBytes: toMegaBytes,
  sizeText: sizeText
};

export {
  DocumentId ,
  Resource ,
}
/* DocumentId Not a pure module */
